<template>
    <div class="my-collapse">
        <div :class="show ? 'my-collapse-show' : 'my-collapse-hide'">
            <div>
                <slot></slot>       
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {show: Boolean}
}
</script>

<style lang="scss" scoped>

.my-collapse {
    overflow: hidden;
}

.my-collapse-show {
    height: 230px;
    transition: height 0.4s;
}

.my-collapse-hide {
    height: 0;
    transition: height 0.4s;
}

</style>