
import module from './module.js'
import rule from './rule.js'
import status from './status.js'

export default {
    namespaced: true,
    state: {
        buildResponse: null,
        upgradeResponse: null
    },
    mutations: {
        setBuildResponse(state, response) {
            state.buildResponse = response
        },
        setUpgradeResponse(state, response) {
            state.upgradeResponse = response
        }
    },
    actions: {
        build({ commit }, data = {}) {
            return new Promise((resolve, reject) => {
                this.dispatch('_get', {url: this.state.base + '/system/build', params: data.params }).then(response => {
                    commit('setBuildResponse', response.data) 
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        upgrade({ commit }, data = {}) {
            return new Promise((resolve, reject) => {
                this.dispatch('_get', {url: this.state.base + '/system/upgrade', params: data.params }).then(response => {
                    commit('setUpgradeResponse', response.data) 
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
    modules: {
        module,
        rule,
        status
    }
}
