<template>
    <div class="my-image">
        <div class="my-image-container" style="width: 240px; height: 280px;">
            <img :src="src">
        </div>
        <div class="my-image-menu">
            <button class="my-image-menu-remove" @click="emitRemove">Remove</button>
            <button class="my-image-menu-upload" @click="emitUpload">Upload</button>
        </div>
    </div>
</template>

<script>
export default {
    props: { src: String },
    methods: {
        emitRemove() {
            this.$emit('remove')
        },
        emitUpload() {
            this.$emit('upload')
        }
    }
}
</script>

<style lang="scss" scoped>

.my-image {
    overflow: hidden;
    position: relative;
}

.my-image-container {

}

.my-image-container img {
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

.my-image-menu {
    position: absolute;
    z-index: 100;
    left: 0; right: 0; bottom: 0;
    padding: 10px;
    display: flex;
    background: rgba(0, 0, 0, 0.213);
    opacity: 0;
    transition: opacity .3s;
}

.my-image:hover > .my-image-menu {
    opacity: 1;
    transition: opacity .3s;
}

.my-image-menu button {
    flex: 1;
    margin: 0px 10px;
    border: none;
    outline: none;
    padding: 4px 8px;
    font-size: 14px;
}

.my-image-menu button:hover {
    transition: 0.4s;
    filter: brightness(0.8);
}

.my-image-menu-remove {
    background: #6c757d;
    color: #fff;
}

.my-image-menu-upload {
    background: #007bff;
    color: #fff;
}

</style>