export default {
    namespaced: true,
    actions: {
        get({ commit }, req = {}) {
            return this.dispatch('_get', {
                url: this.state.base + '/route', 
                params: req.params,
                headers: req.headers 
            })
        },
        post({ commit }, req = {}) {
            return this.dispatch('_post', {
                url: this.state.base + '/route', 
                data: req.data,
                headers: req.headers 
            })
        },
        put({ commit }, req = {}) {
            return this.dispatch('_put', {
                url: this.state.base + '/route', 
                data: req.data,
                headers: req.headers 
            })
        },
        delete({ commit }, req = {}) {
            return this.dispatch('_put', {
                url: this.state.base + '/route', 
                data: req.params,
                headers: req.headers 
            })
        }
    }
}
