<template>
    <div>
        <b-table :striped="striped" :borderless="borderless" :stacked="stacked" :small="small" thead-tr-class="table-header" :items="items" :fields="fields">
            <template v-slot:[_head(field.key)]="data" v-for="field in fields">
                <div :key="field.key">
                    <div v-if="field.label != ''">
                        <b-input :placeholder="data.label || data.key" v-model="select[field.key + '.' + field.filter]" @change="onSearch" :type="field.type"></b-input>
                    </div>
                </div>
            </template>
            <template v-slot:[_cell(field.key)]="data" v-for="(field, i) in fields">
                <div :key="i">
                    <slot :name="'cell(' + field.key + ')'" :item="data.item">
                        {{data.item[field.key]}}
                    </slot>
                </div>
            </template>
            <template v-slot:cell(activities)="data">
                <div class="d-flex justify-content-end">
                    <b-link v-if="showBtnDel" @click="onDelete(data)" class="text-danger ml-3">
                        <i class="fa fa-trash"></i>    
                    </b-link>
                    <b-link v-if="showBtnRef" :to="$route.path + '/' + data.item.id" class="text-primary ml-3">
                        <i class="fa fa-external-link-alt"></i>    
                    </b-link>
                </div>  
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    props: { items: Array, fields: Array, showBtnRef: Boolean, showBtnDel: Boolean, stacked: Boolean, borderless: Boolean, striped: Boolean, small: Boolean },
    methods: {
        _head(key) {
            return `head(${key})`;
        },
        _cell(key) {
            return `cell(${key})`;
        },
        onSearch() {
            for(let prop in this.select)
                if(this.select[prop] == null || this.select[prop] == '')
                    delete this.select[prop]
            this.$emit('search', this.select)
        },
        onUpdate() {
            this.$emit('update', this.object)
        },
        onDelete(data) {
            this.$emit('delete', data)
        },
        onEdit(data) {
            this.$emit('edit', data)
        }
    },
    data() {
        return {
            object: {},
            select: {}
        }
    }
}
</script>

<style lang="scss">
.table-header th {
    padding: 0;
}
.table-header th div
{
    height: 38px;
    position: relative;
}
.table-header input
{  
    height: 38px;
    border: none;
    border-radius: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.table-header input:focus
{
    z-index: 1000;
}
</style>