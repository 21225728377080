import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  // -----------------------------
  // --- Other
  // -----------------------------

  { path: '/',  component: () => import('@/views/dashboard.vue') },

  // -----------------------------
  // --- Credentials
  // -----------------------------

  { 
    path: '/credentials',  
    component: () => import('@/views/credentials/index.vue') 
  },
  { 
    path: '/credentials/users',  
    component: () => import('@/views/credentials/user/users.vue') 
  },
  { 
    path: '/credentials/create-user',  
    component: () => import('@/views/credentials/user/create.vue') 
  },
  { 
    path: '/credentials/users/:id',  
    component: () => import('@/views/credentials/user/user.vue') 
  },
  { 
    path: '/credentials/roles',  
    component: () => import('@/views/credentials/role/roles.vue') 
  },
  { 
    path: '/credentials/create-role',  
    component: () => import('@/views/credentials/role/create.vue') 
  },
  { 
    path: '/credentials/roles/:id',  
    component: () => import('@/views/credentials/role/role.vue') 
  },
  { 
    path: '/credentials/routes',  
    component: () => import('@/views/credentials/route/routes.vue') 
  },
  
  // -----------------------------
  // --- System
  // -----------------------------
  
  { 
    path: '/system',  
    component: () => import('@/views/system/index.vue') 
  },
  { 
    path: '/system/rules',  
    component: () => import('@/views/system/rules.vue') 
  },
  { 
    path: '/system/modules',  
    component: () => import('@/views/system/modules.vue') 
  },
  { 
    path: '/system/configuration',  
    component: () => import('@/views/system/configuration.vue')
   }

]

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
