<!-------------->
<!-- Template -->
<!-------------->
<template>
    <b-row>
        <b-col>
            <span>{{availableLabel}}</span>
            <div :style="'height: ' + height + 'px' + ' !important'" class="h-100 border p-2 overflow-auto mt-1">
                <draggable v-model="mutable_available" group="roles" @start="drag=true" @end="drag=false" class="h-100">
                    <div style="cursor: grab;" class="btn-link" v-for="item in mutable_available" :key="item.id">{{item.name}}</div>
                </draggable>
            </div>
        </b-col>
        <b-col cols="auto">
            <div class="h-100 d-flex align-items-center justify-content-center">
                <b-icon icon="arrow-left-right" scale="1.6"></b-icon>
            </div>
        </b-col>
        <b-col>
            <span>{{selectedLabel}}</span>
            <div :style="'height: ' + height + 'px' + ' !important'" class="h-100 border p-2 overflow-auto mt-1">
                <draggable v-model="mutable_selected" group="roles" @start="drag=true" @end="drag=false" class="h-100" @change="emitInput()">
                    <div style="cursor: grab;" class="btn-link" v-for="item in mutable_selected" :key="item.id">{{item.name}}</div>
                </draggable>
            </div>
        </b-col>
    </b-row>
</template>

<!------------>
<!-- Script -->
<!------------>
<script>
import draggable from 'vuedraggable'
export default {
    props: {'available': {}, 'value': {}, 'height': {}, 'availableLabel': {}, 'selectedLabel': {}},
    data() {
        return {
            mutable_available: this.available.filter(e => !((this.value || []).find(f => f.name == e.name))) || [],
            mutable_selected: this.value || []
        }
    },
    components: {
        draggable,
    },
    methods: {
        emitInput() {
            this.$emit('input', this.mutable_selected)
        }
    }
};
</script>
