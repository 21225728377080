<template>
    <div>
        <div v-if="hasLoaded">
            <b-alert show variant="danger">
                <legend class="alert-heading">{{title}}</legend>
                <p>{{errorMsg}}</p>
                <slot></slot>
            </b-alert>
        </div>
        <div v-else>
            <div class="d-flex justify-content-center my-5">
                <!-- <b-spinner label="Loading..."></b-spinner> -->
                <my-loading></my-loading>
            </div>
        </div>
    </div>
</template>

<script>
import MyLoading from './MyLoading.vue'
export default {
    components: { MyLoading },
    props: { title: String, errorMsg: String, hasLoaded: Boolean }
}
</script>

<style lang="scss" scoped>
.box {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    padding: 0.75rem 1.25rem;
    background: white;
}
</style>