import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import system from './system/index'
import credentials from './credentials/index'

export default new Vuex.Store({
    state: {
        base: 'http://api.dev-v1.flakse.no',
        breadcrumbs: {
            items: []
        },
        handlers: []
    },
    mutations: {
        _RESET_STATE(state, component) {
            let arrayPath = []
            let stringPath = component.$route.path
            if (stringPath == '/' || stringPath == '')
                arrayPath = []
            else
                arrayPath = stringPath.substring(1).split('/')
            let items = []
            let href = ''
            for (let i = 0; i < arrayPath.length; i++) {
                let text = arrayPath[i].charAt(0).toUpperCase() + arrayPath[i].slice(1)
                href += '/' + arrayPath[i]
                if (i == arrayPath.length - 1)
                    items.push({ text: text, href: href, active: true })
                else
                    items.push({ text: text, href: href })
            }
            if (items.length > 0)
                items.unshift({ text: 'Dashboard', href: '/' })
            else
                items.unshift({ text: 'Dashboard', href: '/', active: true })
            state.breadcrumbs.items = items
        },
        _ADD_HANDLER(state, func) {
            state.handlers.push(func)
        },
        _RUN_HANDLERS(state, response) {
            for(let func in state.handlers)  
                state.handlers[func](response)

        }
    },
    actions: {
        _resetState({ commit }, component) {
            commit('_RESET_STATE', component)
        },
        _start({ commit }) {
            this._actions._initialize[0]({ children: this._modules.root._children, root: this })
        },
        _initialize({ commit }, { children, root }) {
            for (let module in children) {
                let actions = children[module]._rawModule.actions
                if (typeof actions._initialize !== 'undefined')
                    actions._initialize({ commit: children[module].context.commit })
                root._actions._initialize[0]({ children: children[module]._children, root: this })
            }
        },
        _addHandler({ commit }, func) {
            commit('_ADD_HANDLER', func)
        },
        _get({ commit }, { url, params, headers }) {
            return new Promise((resolve, reject) => {
                this._vm.$http.get(url, { params: params || null,  headers: headers || null }).then(response => {                    
                    commit('_RUN_HANDLERS', response)
                    resolve(response)
                }).catch(error => {
                    commit('_RUN_HANDLERS', error)
                    reject(error)
                })
            })
        },
        _post({ commit }, { url, data, headers }) {
            return new Promise((resolve, reject) => {
                this._vm.$http.post(url, data, { headers }).then(response => {
                    commit('_RUN_HANDLERS', response)
                    resolve(response)
                }).catch(error => {
                    commit('_RUN_HANDLERS', error)
                    reject(error)
                })
            })
        },
        _put({ commit }, { url, data, headers }) {
            return new Promise((resolve, reject) => {
                this._vm.$http.put(url, data, headers).then(response => {
                    commit('_RUN_HANDLERS', response)
                    resolve(response)
                }).catch(error => {
                    commit('_RUN_HANDLERS', error)
                    reject(error)
                })
            })
        },
        _delete({ commit }, { url, params, headers }) {
            return new Promise((resolve, reject) => {
                this._vm.$http.delete(url, { params: params || null,  headers: headers || null }).then(response => {
                    commit('_RUN_HANDLERS', response)
                    resolve(response)
                }).catch(error => {
                    commit('_RUN_HANDLERS', error)
                    reject(error)
                })
            })
        }
    },
    modules: {
        system,
        credentials
    }
})
