export default {
    namespaced: true,
    state: {
        response: null
    },
    mutations: {
        set(state, response) {
            state.response = response
        }
    },
    actions: {
        get({ commit }) {
            return new Promise((resolve, reject) => {
                this._vm.$http.get(this.state.base + '/settings').then(response => {
                    commit('set', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        post({ commit }, postData) {
            return new Promise((resolve, reject) => {
                this._vm.$http.post(this.state.base + '/settings').then(response => {
                    commit('set', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        put({ commit }, putData) {
            return new Promise((resolve, reject) => {
                this._vm.$http.put(this.state.base + '/settings').then(response => {
                    commit('set', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        delete({ commit }) {
            return new Promise((resolve, reject) => {
                this._vm.$http.delete(this.state.base + '/settings').then(response => {
                    commit('set', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}
