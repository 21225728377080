<!-------------->
<!-- Template -->
<!-------------->
<template>
    <div class="flex">

        <div :class="!locked ? 'field field-border' : 'field'">

            <!-- Select display  -->
            <div v-if="type == 'select'" >
                <span :class=" locked ? 'text-muted' : ''" class="field-value" @click="focusField">{{activeOption ? activeOption.name : ''}}</span>
            </div>

            <!-- Textarea display -->
            <div v-else-if="type == 'textarea'">
                <span :class=" locked ? 'text-muted' : ''" class="field-value" style="min-height: 100px; max-height: 200px; overflow: auto; white-space: pre-wrap;" @click="focusField">{{value}}</span>
            </div>

            <!-- Normal display -->
            <div v-else>
                <span :class=" locked ? 'text-muted' : ''" class="field-value" @click="focusField">{{value}}</span>
            </div>

            <!-- Select input -->
            <div v-if="type == 'select'">
                <div v-show="showField()">
                    <select v-model="newValue" ref="elem" class="auto-input form-control" @focus="focusField" @change="changeField()" @blur="blurField()">
                        <option v-for="option in options" :key="option.value" :value="option.value">{{option.name}}</option>
                    </select>
                    <div style="position: absolute; top: 50%; right: 0; transform: translate(-3px,-50%); height: 100%; pointer-event: none !important;">
                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                    </div>
                </div>
            </div>

            <!-- Textarea input -->
            <div v-else-if="type == 'textarea'">
                <div v-show="showField()">
                    <textarea class="auto-input form-control" ref="elem" style="min-height: 100%; resize: none;" v-model="newValue" @focus="focusField" @change="changeField()" @blur="blurField()"></textarea>
                </div>
            </div>

            <!-- Normal input -->
            <div v-else>
                <div v-show="showField()">
                    <input v-model="newValue" ref="elem" class="auto-input form-control" @focus="focusField" @change="changeField()" @blur="blurField()" :type="type || 'text'">
                </div>
            </div>
        </div>

    </div>
</template>

<!------------>
<!-- Script -->
<!------------>
<script>

export default {
    props: 
    {
        'locked': {
            'type': Boolean
        },
        'value': {

        },
        'type': {

        },
        'id': {
            
        },
        'options': {

        },
        'valueName': {

        },
        'idName': {

        }
    },
    data()
    {
        return {
            editable: false,
            newValue: this.value
        }
    },

    computed: {
        activeOption() {

            return this.options.find(e => e.value == this.value)
        },
        oldValue() {
            return this.value;
        }
    },

    methods: {
        focusField() {
            if(!this.locked)
            {
                this.editable = true
                setTimeout(() => { this.$refs.elem.focus(); }, 0);
            }
        },
        blurField() {
            this.editable = false
        },
        showField() {
            return (this.newValue == null || ('' + this.newValue).trim() === '' || this.editable == true) && !this.locked
        },
        changeField() {
            this.$emit('change', {id: this.id, newValue: this.newValue, oldValue: this.oldValue, idName: this.idName, valueName: this.valueName })
            this.$emit('after-change', {id: this.id, newValue: this.newValue, oldValue: this.oldValue, idName: this.idName, valueName: this.valueName })
        }
    }
};

</script>


<!----------->
<!-- Style -->
<!----------->
<style lang="scss">

.flex {
    display: flex;
}

.flex .field {
    flex: 1;
}

.icon {
    color: #0275d8;
    width: 20px;
    margin-right: 10px;
    display: inline-block;
}

.field {
    display: inline-block;
    position: relative !important;
}

.field-border {
    outline: 1px solid transparent;
    transition: 0.5s;
}

.field-border:hover {
    outline: 1px solid #dee2e6 !important;
    transition: 0.5s;
}

.field-value {
    display: block;
    min-width: 100%;
    min-height: inherit !important;
}

.auto-input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    height: inherit;
    font-size: inherit;
    outline: 1px solid #dee2e6 !important;
    -webkit-appearance: none;
    -moz-appearance: none; 
    appearance: 0;
}

.not-visible {
    visibility: hidden;
}

</style>
