export default {
    namespaced: true,
    state: {
        response: null
    },
    mutations: {
        set(state, response) {
            state.response = response
        }
    },
    actions: {
        get({ commit }) {
            this._vm.$http.get(this.state.base + '/group').then(response => {
                commit('set', response.data)
            })
        },
        post({ commit }, postData) {
            this._vm.$http.post(this.state.base + '/group', postData).then(response => {
                commit('set', response.data)
            })
        },
        put({ commit }, putData) {
            this._vm.$http.put(this.state.base + '/group', putData).then(response => {
                commit('set', response.data)
            })
        },
        delete({ commit }) {
            this._vm.$http.delete(this.state.base + '/group').then(response => {
                commit('set', response.data)
            })
        }
    }
}
