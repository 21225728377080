<template>
    <div>
        <b-modal :id="id" :title="title" header-class="border-0" footer-class="border-0" content-class="rounded-0" :centered="centered" :size="size || 'lg'" @ok="emitOk" @cancle="emitCancle">
            
            <slot></slot>
            
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button size="sm" squared variant="secondary" @click="cancel()">{{cancleText || 'Cancle'}}</b-button>
                <b-button size="sm" squared variant="primary" @click="ok()">{{okText || 'Ok'}}</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    props: { title: null, id: null, cancleText: String, okText: String, size: String, centered: Boolean },
    methods: {
        emitOk() {
            this.$emit('ok')
        },
        emitCancle() {
            this.$emit('cancle')
        }
    }
}
</script>