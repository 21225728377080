<!-------------->
<!-- Template -->
<!-------------->
<template>
    <div class="my-input">
        <div class="my-input-data" :class="(isContenteditable || locked) ? 'my-input-text' : 'my-input-pointer'" @click="setFocusEditor(true)" @blur="setFocusEditor(false)" ref="editor" :contenteditable="isContenteditable" v-html="value" @input="setNewValue"></div>
        <div class="my-input-container" :class="showContainer ? 'my-input-visible' : ''">
            <div class="my-input-border"></div>
            <div class="my-input-symbol">
                <div v-if="locked">
                    <i class="fa fa-lock"></i>
                </div>
                <div>
                    <i class="fa fa-pen"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<!------------>
<!-- Script -->
<!------------>
<script>

export default {
    props: { value: null, locked: Boolean, type: String, options: Object },
    data() {
        return {
            isContenteditable: false,
            showContainer: false,
            newValue: this.value,
            oldValue: this.value
        }
    },
    methods: {
        setNewValue(e) {
            this.newValue = e.srcElement.innerText
        },
        setFocusEditor(bool) {
            if(bool) {
                if(!this.locked) {
                    this.isContenteditable = true
                    this.showContainer = true
                    setTimeout(() => { this.$refs.editor.focus(); }, 0);
                }
                else {
                    let range = document.createRange()
                    range.selectNode(this.$refs.editor)
                    window.getSelection().removeAllRanges()
                    window.getSelection().addRange(range)
                }
            }
            else {
                if(!this.locked) {
                    this.isContenteditable = false
                    this.showContainer = false
                    if(this.newValue != this.oldValue) {
                        this.$emit('change', { newValue: this.newValue, oldValue: this.oldValue, options: this.options })
                        this.oldValue = this.newValue
                    }
                }
            } 
        }
    }
}

</script>


<!----------->
<!-- Style -->
<!----------->
<style lang="scss">

.my-input {
    position: relative;
}

.my-input-data {
    font-family: inherit;
    outline: none;
}

.my-input-data:empty:before {
  content: "\200b"; // unicode zero width space character
}

.my-input-container {
    position: absolute;
    z-index: 1;
    top: 0; right: 0; bottom: 0; left: 0;
    opacity: 0;
    transition: opacity .3s;
}

.my-input:hover > .my-input-container {
    opacity: 1;
    transition: opacity .3s;
}

.my-input-border {
    overflow: hidden;
    position: absolute;
    outline: 1pt solid #ced4da;
    top: 0; right: 0; bottom: 0; left: 0;
}

.my-input-symbol {
    overflow: hidden;
    position: absolute;
    border-radius: 0px 1pt 1pt 0px;
    background: #ced4da;
    padding: 0px 5px;
    top: 0; right: 0px; bottom: 0;
}

.my-input-symbol, .my-input-border, .my-input-container {
    pointer-events: none;
}

.my-input-visible {
    display: block !important;
    opacity: 1 !important;
}

.my-input-pointer {
    cursor: pointer;
}

.my-input-text {
    cursor: text;
}

</style>
