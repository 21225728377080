export default {
    namespaced: true,
    state: {
        response: null
    },
    actions: {
        get({ commit }, req = {}) {
            return this.dispatch('_get', { url: this.state.base + '/user/group', params: req.params })
        },
        post({ commit }, data = {}) {
            return this.dispatch('_get', { url: this.state.base + '/user/group', data: req.data, headers: req.headers })
        },
        put({ commit }, data = {}) {
            return this.dispatch('_put', { url: this.state.base + '/user/group', data: req.data, headers: req.headers })
        },
        delete({ commit }, data = {}) {
            return this.dispatch('_delete', { url: this.state.base + '/user/group', params: req.params })
        }
    }
}
