import Vue from 'vue'

import roles from './userRole.js'
import groups from './userGroup.js'

export default {
    namespaced: true,
    state: {
        isOnline: false,
        session: null
    },
    mutations: {
        
        'set'(state, response) {
            state.response = response
        },
        
        'set_online'(state, { session }) {
            Vue.http.headers.common['Authorization'] = 'Bearer ' + session.access_token
            window.localStorage.setItem('SESSION', JSON.stringify(session))
            state.isOnline = true
        },
        
        'set_offline'(state) {
            delete Vue.http.headers.common['Authorization']
            window.localStorage.removeItem('SESSION')
            state.isOnline = false
        },
        
        'compute_session'(state, { session }) {
            let payload = JSON.parse(atob(session.access_token.split('.')[1]))

            let time = new Date().getTime() / 1000

            if(payload['exp'] < time)
                window.localStorage.removeItem('SESSION')
        },

        'initialize'(state, { commit }) {

            this.dispatch('_addHandler', (response) => {

                let session = null

                session = JSON.parse(window.localStorage.getItem('SESSION')) 
                if(session == null || session.access_token == null) { 
                    commit('set_offline')
                    return 
                }
                
                commit('compute_session', { session })

                session = JSON.parse(window.localStorage.getItem('SESSION')) 
                if(session == null || session.access_token == null) { 
                   commit('set_offline')
                   return 
                }

                if(response.data.credentials != null && response.data.credentials.refresh_token != null) {
                    let headers = {
                        Authorization: 'Bearer ' + response.data.credentials.refresh_token
                    }
                    this._vm.$http.get(this.state.base + '/credentials/refresh', { params: null, headers: headers || null }).then(response => {
                        commit('set_online', { session: response.data.route })
                    })
                }
            })

            let session = null

            session = JSON.parse(window.localStorage.getItem('SESSION')) 
            if(session == null || session.access_token == null) { 
                commit('set_offline')
                return 
            }
            
            commit('compute_session', { session })
            
            session = JSON.parse(window.localStorage.getItem('SESSION')) 
            if(session == null || session.access_token == null) { 
               commit('set_offline')
               return 
            }

            commit('set_online', { session })
        }
    },
    actions: {

        _initialize({ commit }) {
            commit('initialize', { commit })
        },

        login({ commit }, data = {}) {
            return new Promise((resolve, reject) => {
                this._vm.$http.get(this.state.base + '/credentials/login', { params: data.params || null, headers: data.headers || null }).then(response => {
                    commit('set_online', { session: response.data.route })
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        logout({ commit }, data = {}){
            commit('set_offline')
        },

        get({ commit }, req = {}) {
            return this.dispatch('_get', { 
                url: this.state.base + '/user', 
                params: req.params, 
                headers: req.headers 
            })
        },

        post({ commit }, req = {}) {
            return this.dispatch('_post', { 
                url: this.state.base + '/user', 
                data: req.data, 
                headers: req.headers 
            })
        },

        put({ commit }, req = {}) {
            return this.dispatch('_put', { 
                url: this.state.base + '/user', 
                data: req.data, 
                headers: req.headers 
            })
        },

        delete({ commit }, req = {}) {
            return this.dispatch('_delete', { 
                url: this.state.base + '/user', 
                params: req.params, 
                headers: req.headers 
            })
        },

        setRoles({ commit }, req = {}) {
            return this.dispatch('_post', { 
                url: this.state.base + '/user/set-roles', 
                data: req.data, 
                headers: req.headers 
            })
        },

        setImage({ commit }, req = {}) {
            let formData = new FormData()
            let data = req.data
            for(let x in data) 
                formData.append(x, data[x])
            return this.dispatch('_post', { 
                url: this.state.base + '/user/set-image', 
                data: formData, 
                headers: { 'Content-Type': 'multipart/form-data' } 
            })
        },

        removeImage({ commit }, req = {}) {
            return this.dispatch('_post', { 
                url: this.state.base + '/user/remove-image', 
                data: req.data, 
                headers: req.headers 
            })
        }
    },
    modules: {
        roles,
        groups
    }
}
