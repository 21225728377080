<template>
    <div id="app">

        <transition name="fade">
            <div v-show="$store.state.credentials.user.isOnline" style="height: 100vh;">

                <div class="d-flex h-100">

                    <!-- Sidebar left -->
                    <section>
                        <!-- <div class="sidebar">
                            <b-navbar type="dark" variant="primary" :style="brandHeader">
                                <b-navbar-nav class="m-auto">
                                    <b-navbar-brand to="/">Dashboard</b-navbar-brand>
                                </b-navbar-nav>
                            </b-navbar>
                            <div class="menu">
                                <div v-if="$store.state.system.module.response != null && $store.state.system.module.response.route.filter(item => item.type == 'primary').length > 0">
                                    <h6>Main</h6>
                                    <ul class="list-unstyled">
                                        <li v-for="(item, i) in $store.state.system.module.response.route" :key="i">
                                            <div class="list-item" v-if="item.type == 'primary'">
                                                <b-link :to="item.root_path" class="">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span>{{item.name}}</span>
                                                    </div>
                                                </b-link>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div v-if="$store.state.system.module.response != null &&$store.state.system.module.response.route.filter(item => item.type == 'secondary').length > 0">
                                    <h6>Modules</h6>
                                    <ul class="list-unstyled">
                                        <li v-for="(item, i) in $store.state.system.module.response.route" :key="i">
                                            <div class="list-item" v-if="item.type == 'secondary'">
                                                <b-link :to="item.root_path" class="">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span>{{item.name}}</span>
                                                    </div>
                                                </b-link>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                    </section>

                    <div style="flex: 1;">

                        <!-- Content -->
                        <section style="height: 100%;" class="d-flex flex-column">
                            <b-navbar type="dark" variant="primary" :style="contentHeader">
                                <b-navbar-nav class="mr-auto">
                                    <b-nav-form>
                                        <b-form-input size="sm" placeholder="Search" style="width: 400px;"></b-form-input>
                                    </b-nav-form>
                                </b-navbar-nav>
                                <b-navbar-nav class="ml-auto">
                                    <b-nav-item-dropdown text="Thomas Fakse" right>
                                        <b-dropdown-item>Account</b-dropdown-item>
                                        <b-dropdown-item @click="$store.dispatch('credentials/user/logout')">Sign Out</b-dropdown-item>
                                    </b-nav-item-dropdown>
                                </b-navbar-nav>
                            </b-navbar>
                            <div style="overflow-y: scroll;">
                                <br>
                                <b-container>
                                    <div>
                                        <b-breadcrumb class="rounded-0">
                                            <b-breadcrumb-item v-for="(item, i) in $store.state.breadcrumbs.items" :key="i" :to="item.href" :active="item.active">{{item.text}}</b-breadcrumb-item>
                                        </b-breadcrumb>
                                        <router-view></router-view>
                                    </div>
                                </b-container>
                                <br>
                            </div>
                        </section>

                    </div>

                </div>

            </div>
        </transition>

        <transition name="fade">
            <div v-show="!$store.state.credentials.user.isOnline">

                <!-- Title -->
                <section class="login">
                    <div class="brand">
                        <img src="@/assets/logo.png" alt="logo">
                    </div>
                </section>            

                <!-- Login -->
                <section class="login">
                    <div class="form">
                        <h4>Login</h4>
                        <br>
                        <b-form-group>
                            <slot name="label">
                                <p class="float-left">E-Mail Address or Username</p>
                            </slot>
                            <b-input type="text" v-model="username"></b-input>
                        </b-form-group>
                        <b-form-group>
                            <slot name="label">
                                <p class="float-left">Password</p>
                                <p class="float-right"><a href="#">Forgot Password?</a></p>
                            </slot>
                            <b-input type="password" v-model="password"></b-input>
                        </b-form-group>
                        <b-form-group>
                            <b-checkbox>Remember Me</b-checkbox>
                        </b-form-group>
                        <b-button block variant="primary" @click="$store.dispatch('credentials/user/login', { headers: headers })">Login</b-button>
                        <br>
                        <div class="text-center">
                            <span>Don't have an account? <a href="#">Create One</a></span>
                        </div>
                    </div>
                </section>

                <!-- Copyright -->
                <section class="login">
                    <div class="copyright">
                        <div class="text-center text-muted">
                            <span>Copyright © 2020 — Company Name</span>
                        </div>
                    </div>
                </section>  

            </div>
        </transition>

    </div>
</template>

<script>
  export default {
    computed: {
        brandHeader() {
            return 'filter:brightness(120%);'
        },
        contentHeader() {
            return ''
        },
        headers() {
            return {
                Authorization: 'Basic ' + btoa(this.username + ':' + this.password)
            }
        }
    },
    data() {
        return {
            username: null,
            password: null
        }
    },
    created() {
        this.$store.dispatch('_start')
        this.$store.dispatch('system/module/get')
    },
    watch: {
        '$route.path': {
            deep: true,
            immediate: true,
            handler() {
                this.$store.dispatch('_resetState', this)
            }
        }
    }
  }
</script>

<style lang="scss">

html, body {
    background: #f7f7f7 !important;
    overflow: hidden;
}

.login {
    padding: 0 20px;
    max-width: 420px;
    margin:auto;
    font-size: 14px;
}

.login p {
    font-size: 14px;
    margin-bottom: 5px;
}

.brand {
    width: 90px; 
    height: 90px;
    box-shadow: 0 4px 8px rgba(0,0,0,.05);
    overflow: hidden;
    border-radius: 50%;
    margin: 40px auto;
}

.brand img {
    width: 100%;
}

.form {
    box-shadow: 0 4px 8px rgba(0,0,0,.05);
    padding: calc(1.25rem + 10px);
    background: white;
}

.copyright {
    margin: 40px auto; 
}

.sidebar {
    width: 260px;
    box-shadow: 0 4px 8px rgba(0,0,0,.05);
    background: white;
    height: 100%;
}

.menu {
    padding: 1.25rem 1rem;
}

.list-item a:hover {
    background: #d8f2fa;
    transition: 0.25s;
    color: black;
}

.list-item a {
    transition: 0.25s;
    font-weight: 400;
    display: block;
    padding: 0.50rem 1rem !important;
    text-decoration: none !important;
    color: black;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

</style>