
import credentials from './credentials.js'
import group from './group.js'
import history from './history.js'
import role from './role.js'
import route from './route.js'
import settings from './settings.js'
import user from './user.js'

export default {
    namespaced: true,
    state: {

    },
    mutations: {

    },
    actions: {

    },
    modules: {
        credentials,
        group,
        history,
        role,
        route,
        settings,
        user
    }
}
