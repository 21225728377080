import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Install vue-resource (http request plugin)
import VueResource from 'vue-resource';
Vue.use(VueResource);

// Import bootstrap vue
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Import all local components
import MyCollapse from '@/components/MyCollapse.vue'
Vue.component('my-collapse', MyCollapse)

import MyImageInput from '@/components/MyImageInput.vue'
Vue.component('my-image-input', MyImageInput)

import MyLoader from '@/components/MyLoader.vue'
Vue.component('my-loader', MyLoader)

import MyModal from '@/components/MyModal.vue'
Vue.component('my-modal', MyModal)

import MyLoading from '@/components/MyLoading.vue'
Vue.component('my-loading', MyLoading)

import MyTable from '@/components/MyTable.vue'
Vue.component('my-table', MyTable)

import MyBox from '@/components/MyBox.vue'
Vue.component('my-box', MyBox)

import MyDataInput from '@/components/MyDataInput.vue'
Vue.component('my-data-input', MyDataInput)

import MyAutoInput from '@/components/MyAutoInput.vue'
Vue.component('my-auto-input', MyAutoInput)

import MyDragAndDrop from '@/components/MyDragAndDrop.vue'
Vue.component('my-drag-and-drop', MyDragAndDrop)

// Loading spesific fonts and icons
import '@fortawesome/fontawesome-free/css/all.min.css'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
